import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import HolisticStatus from '../common/HolisticStatus';
import { getErrorMessage } from '../../utils/messages';
import logo from '../../logo.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    home: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    logo: {
      height: 30,
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.grey[900],
    },
  })
);

const Header: FC<{}> = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (err) {
      const { message, variant }: any = getErrorMessage(err);
      enqueueSnackbar(message, { variant });
    }
  };

  return (
    <AppBar position="static" color="transparent">
      <Toolbar>
        <Toolbar>
          <Link className={classes.home} to="/">
            <img className={classes.logo} src={logo} alt="Sky" />
          </Link>
        </Toolbar>
        <Typography variant="h6" className={classes.title}>
          Teachable Machine
        </Typography>
        <HolisticStatus />
        <Link to="/" className={classes.link}>
          <Button color="inherit">Projects</Button>
        </Link>
        <Link to="/profile" className={classes.link}>
          <Button color="inherit">Profile</Button>
        </Link>
        <Button onClick={handleLogout} color="inherit">
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
