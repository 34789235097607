/* eslint-disable react/no-array-index-key */
import React, { useCallback, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import HolisticFrame from '../../utils/HolisticFrame';
import { ProjectType } from '../../API';
import FrameGroup from './FrameGroup';

const CARD_SIZE = 120;

export type FrameListProps = {
  frames: HolisticFrame[];
  emptyText?: string;
  projectType: ProjectType;
  onRemove: (frames: HolisticFrame[]) => void;
  maximumHeight?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    frameCount: {
      paddingBottom: theme.spacing(2),
    },
  })
);

const FrameList = ({
  frames,
  projectType,
  emptyText,
  onRemove,
  maximumHeight = CARD_SIZE * 3.5,
}: FrameListProps): JSX.Element => {
  const classes = useStyles();
  const frameCountTitleRef = useRef<HTMLSpanElement>(null);

  const handleRemove = (itemIndex: number) =>
    onRemove(frames.filter((_, index) => itemIndex !== index));

  const frameCount = frames.length;

  const getItemByRow = useCallback((width: number) => Math.floor(width / CARD_SIZE), []);

  const getItemByColumn = useCallback(
    (width: number) => Math.ceil(frameCount / getItemByRow(width)),
    [frameCount, getItemByRow]
  );

  const titleHeight = frameCountTitleRef?.current?.offsetHeight || 0;

  const getHeight = useCallback(
    (width: number) => {
      const fullHeight = getItemByColumn(width) * CARD_SIZE;
      return fullHeight >= maximumHeight ? maximumHeight - titleHeight : fullHeight;
    },
    [getItemByColumn, maximumHeight, titleHeight]
  );

  if (frames && !frameCount) {
    return (
      <Typography component="h5" variant="subtitle1">
        {emptyText ?? 'No frames to display please record the video.'}
      </Typography>
    );
  }

  return (
    <>
      <Typography ref={frameCountTitleRef} className={classes.frameCount}>
        {frameCount} frames
      </Typography>
      <AutoSizer disableHeight>
        {({ width }) => (
          <List
            height={getHeight(width)}
            itemCount={getItemByColumn(width)}
            itemSize={CARD_SIZE}
            width={width}
          >
            {({ index, style }) => (
              <Box display="flex" style={style} key={index}>
                <FrameGroup
                  frames={frames}
                  onRemove={handleRemove}
                  itemInRow={getItemByRow(width)}
                  projectType={projectType}
                  groupIndex={index}
                />
              </Box>
            )}
          </List>
        )}
      </AutoSizer>
    </>
  );
};
export default FrameList;
